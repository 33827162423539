.travel-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 14px;
  background-color: $white;
  position: absolute;
  bottom: -8rem;
  z-index: 3;
  width: 62.5%;
  padding: 24px;
  box-shadow: 0px 4px 4px 0px $box_shadow;
  min-height: 17rem;

  @media (max-width: 1100px) {
    top: 20px;
    bottom: 0px;
    position: relative;
    width: 90%;
  }

  .travel-radio-group {
    display: inline-flex;
    gap: 2rem;
  }

  .travel-radio-group>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .travel-radio-group label {
    color: $primary-black;
    font-size: 16px;
    font-weight: 600;
    line-height: 27.841px;
  }

  input[type="radio"] {
    appearance: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/ic-radio-unchecked.svg') center/cover no-repeat;

    &:checked {
      background: url('../assets/ic-radio-checked.svg') center/cover no-repeat;
    }
  }

  // Grid Inputs
  .travel-card-grid {
    display: grid;
    margin-top: 15px;
    grid-template-columns: repeat(5, 1fr);
    gap: 6px;
  

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 20px;
    }

    align-items: center;
  }

  .travel-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    border: 1px solid $primary-orange;
    border-radius: 14px;
    height: 80px;
    padding: 12px;
    position: relative;
  }

  .travel-item.disabled {
    border: 1px solid gray;
    pointer-events: none;
  }

  .travel-item-top {
    height: 30px;
    display: inline-flex;
    gap: 6px;

    img {
      margin-top: 5px;
      height: 1.2rem;
    }

    p {
      color: $primary-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 27.841px;
    }
  }

  .travel-search-btn {
    width: 200px;
    height: 55px;
    background-color: $primary-orange;
    color: $white;
    border: none;
    font-size: 16px;
    border-radius: 13px;
    cursor: pointer;
    position: absolute;
    bottom: -2rem;
    right: 3rem;
    box-shadow: 0px 4px 4px 0px $box_shadow;

    @media (max-width: 800px) {
      width: 100%;
      height: 65px;
      right: 0px;
      bottom: 10px;
      position: relative;
      padding: 20px 60px;
    }
  }

  .travel-search-loader {
    width: 200px;
    height: 55px;
    background-color: $primary-orange;
    color: $white;
    border: none;
    align-items: center;
    border-radius: 13px;
    position: absolute;
    bottom: -2rem;
    right: 3rem;
    box-shadow: 0px 4px 4px 0px $box_shadow;

    @media (max-width: 800px) {
      width: 100%;
      height: 70px;
      right: 0px;
      bottom: 10px;
      position: relative;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border: 4px solid #f3f3f3;
    border-top: 5px solid $primary-orange;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .travel-search-btn:hover {
    background-color: darkorange !important;
  }

  .custom-datepicker {
    width: 100%;
    border: none;
    color: $primary-orange;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.841px;
  }

  .custom-datepicker::placeholder {
    color: gray;
    font-size: 15px;
    font-style: bold;
    font-weight: 500;
    line-height: 27.841px;
  }

  .custom-datepicker:focus {
    outline: none;
  }

  .react-datepicker__day--selected {
    background-color: $primary-orange;
    color: #FFFFFF;
  }

  .react-datepicker__day:hover {
    color: $primary-orange;
  }

  .travel-seats {
    display: flex;
    flex-direction: row;
  }

  .input-seats {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .input-seats input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
  }

  .travel-child-info{
    align-self: flex-end;
    margin-bottom: 20px;
    p {
      color: $primary-orange;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .switch-button-container {
    position: absolute;
    z-index: 1;
    background-color: transparent;

    @media (max-width: 1200px) {
      margin-bottom: 170px;
    }

    @media (max-width: 800px) {
      visibility: hidden;
    }
  }

  .switch-button {
    background-color: transparent;
    border: none;
  }

  .switch-container {
    position: relative;
    display: inline-block;
  }

  .switch-container-icon {
    position: relative;

    @media (max-width: 800px) {
      transform: rotate(90deg)
    }
  }

  .switch-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s;
  }

  .switch-icon:hover {
    transform: translate(-50%, -50%) scale(1.2)
  }

  .switch-icon.rotated:hover {
    transform: translate(-50%, -50%) scale(1.2) rotate(180deg);
  }

  .switch-icon.rotated {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .input-seats span,
  .input-seats input {
    color: $primary-orange;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.841px;
  }

  .input-seats input::placeholder {
    color: gray;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.841px;
  }
}