.passengers-input-card {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    h2 {
        font-size: 16px;
        font-weight: 600;
        color: $primary-black;
    }
    .passengers-input-body {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
    }
}