.phone-body {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 10px;

    .phone-image {
        width: 17px;
        height: 17px;
        margin-right: 5px;
    }

    .phone-number {
        font-size: 15px;
        font-weight: 500;
        color: $primary-orange;
    }
}