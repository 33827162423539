.icon-container {
  display: flex;

  .icon {
    background-color: $primary-orange;
    font-size: 20px;
    /* Adjust size as needed */
    border-radius: 25%;
    /* Makes icons circular */
    margin: 10px 10px;
    /* Space between icons */
    color: $white;
    /* Icon color */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    /* Aliniere orizontală în centru */
    align-items: center;
    /* Aliniere verticală în centru */
  }
  
  .icon:hover {
    background-color: darkorange;
    /* Slight color change on hover */
  }
  
}
