.news-info-card {
    background-color: $white;
    border-radius: 10px;
    font-family: Arial, sans-serif;

    .news-card-image {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
        max-height: 190px;
    }

    .news-card-title {
        margin: 10px 0 10px 10px;
        color: $primary-black;
        font-size: 18px;
        font-weight: 500;
    }

    .news-card-description {
        padding-left: 10px;
        padding-right: 10px;
        color: #797979;
        font-size: 14px;
        min-height: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .news-card-footer {
        margin: 10px 10px 10px 10px;
        display: flex;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        justify-content: space-between;
        align-items: center;
    }

    .news-timestamp {
        flex: 1;
        color: #BABFCE;
        font-size: 12px;
    }

    .news-read-more {
        color: $primary-orange;
        font-size: 12px;
        text-decoration: none;
        font-weight: 600;
    }

    .news-read-more:hover {
        color: darkorange !important;
        letter-spacing: 0.2px;
        box-shadow: none;
    }
}