.price-header-container {
    background-color: $primary-orange;
    border-radius: 10px;
    display: flex;
    padding: 10px;

    span {
        font-size: 18px;
        font-weight: 500;
        color: $white;
        width: 25%;

        @media (max-width: 500px) {
            font-size: 16px;
        }
    }
}