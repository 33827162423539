.step-body {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 13px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;

    /* Create 3 equal columns */
    @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
        /* Display 2 items per row on medium screens */
    }

    @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
        /* Display 1 item per row on small screens */
        padding: 8px 0;
    }
}

.step-card,
.step-orange-card {
    display: flex;
    padding-top: 20px;
    height: 335px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.step-card {
    background-color: $white;
}

.step-orange-card {
    background-color: $primary-orange;
}

.step-icon {
    border-radius: 50%;
    width: 90px;
    height: 90px;
}

.step-name,
.step-name-orange {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.step-name {
    color: $primary-black;
}

.step-name-orange {
    color: $white;
}

.step-description,
.step-description-orange {
    font-size: 16px;
    text-align: center;
}

.step-description {
    color: $primary-black
}

.step-description-orange {
    color: $white;
}