.country-card{
    background-color: $white;
    border: 1px solid $primary-orange;
    border-radius: 14px;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.country-icon {
    border-radius: 50%;
    width: 90px;
    height: 90px;
}

.country-name,
.country-price {
    font-size: 20px;
    font-weight: bold;
}

.country-name {
    color: $primary-black;
    text-align: center;
}

.country-price {
    color: $primary-orange;
}

