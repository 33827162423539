.news-main-container {
    background-color: $background;
    min-height: 100vh;
    .news-main-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        margin-bottom: 40px;

        @media (max-width: 500px) {
            width: 90%;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            color: $primary-black;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }

    .news-card-grid-main {
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        /* Create 3 equal columns */
        @media (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
            /* Display 2 items per row on medium screens */
        }

        @media (max-width: 800px) {
            grid-template-columns: repeat(1, 1fr);
            /* Display 1 item per row on small screens */
        }
    }
}