.contact-info-card {
    background-color: $white;
    display: block;
    border-radius: 10px;
    padding: 20px;

    span {
        font-size: 16px;
        font-weight: 500;
        color: $primary-black;
    }

    .other-info-container {
        display: block;
    }

    .other-info {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        color: $primary-orange;
    }

    .title-container {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .title-info {
            font-size: 18px;
            font-weight: 600;
            color: $primary-orange;
        }
    }

    .line {
        margin-top: 30px;
        border-top: 2px solid $primary-black;
    }

    .info-container {
        display: flex;
        margin-top: 10px;
        align-items: center;
        .info-image {
            width: 37px;
            height: 37px;
        }
        .info-text {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 600;
            color: $primary-black;
        }

        .phone-body {
            justify-content: start;
        
            .phone-number {
              color: $primary-black;
            }
          }
    }

    .btn-link:hover {
        color: $primary-black !important;
        box-shadow: none;
    }

    .btn-link {
        color: $primary-orange !important;
    }

}