.reason {
  .travel-card-icon,
  h3 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* Center the text for title */
  }

  h3 {
    font-weight: bolder;
    font-size: 18px;
    color: $primary-orange;
  }

  p {
    font-size: 14px;
    color: $primary-black;
    text-align: center;
  }

  .travel-card-icon {
    width: 50px;
    height: 40%;
    margin-bottom: 5px;
  }
}