@import "./variables.scss";
@import "./navigation.scss";
@import "./footer.scss";
@import "./general.scss";
@import "./welcome.scss";
@import "./react-picker.scss";
@import "./modal.scss";
@import "./destination-card.scss";
@import "./news-card.scss";
@import "./travel.scss";
@import "./travel-reason.scss";
@import "./country-card.scss";
@import "./social.scss";
@import "./dropdown.scss";
@import "./parcels.scss";
@import "./parcels-step-card.scss";
@import "./news-main.card.scss";
@import "./news.scss";
@import "./locations.scss";
@import "./location-card.scss";
@import "./phone.scss";
@import "./switch-tab.scss";
@import "./rentals.scss";
@import "./rental-card.scss";
@import "./rentals-body-card.scss";
@import "./other-news-card.scss";
@import "./news-details.scss";
@import "./news-details-card.scss";
@import "./loader.scss";
@import "./contact-us-form-card.scss";
@import "./contact-us.scss";
@import "./contact-info.scss";
@import "./header.scss";
@import "./destination.scss";
@import "./price-item.scss";
@import "./price-header.scss";
@import  "./custom-calendar.scss";
@import "./buy-ticket.scss";
@import "./ticket-info.scss";
@import "./person-input.scss";
@import "./passengers-card.scss";
@import "./invoice-inputs-card.scss";
@import "./extra-info-card.scss";
@import "./progress-bar-container.scss";
@import "./buy-ticket-page2.scss";
@import "./ticket-info-review.scss";
@import "./info-page.scss";
@import "./buy-ticket-finish.scss";
@import "./animate.scss";
@import "./app-promot-section.scss";

.App {
  background-color: $background;
  min-height: 100vh;
}

a {
  text-decoration: none !important;

  &:hover {
    color: $white !important;
  }
}

.red-text {
  color: red;
  font-size: 16px;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}
