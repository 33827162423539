.contact-container {
    min-height: 100vh;

    .contact-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (max-width: 500px) {
            width: 90%;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            color: $primary-black;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }

    .contact-info-grid {
        display: flex;

        @media (max-width: 900px) {
            display: block;
        }

        .contact-info {
            margin-left: 20px;
            margin-top: 0px;
            @media (max-width: 900px) {
                margin-left: 0px;
                margin-top: 20px;
            }

        }

        .contact-form {
            width: 60%;

            @media (max-width: 900px) {
                width: 100%;
            }
        }
    }

    .maps-container {
        height: 460px;
        margin-top: 20px;
    }
}