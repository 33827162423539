.ticket-info-review-body {
    display: block;
    h2 {
        color: $primary-black;
        font-size: 16px;
        font-weight: 600;
    }

    span {
        color: $primary-black;
        font-size: 14px;
        font-weight: 600;
    }
    .text-container {
        margin-top: 10px;
    }

    .passengers-info{
        display: block;
        background-color: $white;
        border-radius: 10px;
        padding: 20px;
        .passengers-body {
           margin-left: 30px;
        }
    }    
    
    .invoice-info{
        display: block;
        background-color: $white;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        .invoice-body {
           margin-left: 30px;
           display: block;
        }
    } 

    .extra-info{
        display: block;
        background-color: $white;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        .extra-body {
           margin-left: 30px;
           display: block;
        }
    }   
}