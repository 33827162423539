.other-news-card {
    display: flex;
    margin-top: 10px;

    .other-news-image {
        width: 190px;
        height: 125px;
        border-radius: 10%;
    }

    .other-news-text-container {
        display: block;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        max-height: 125px;

        h2 {
            font-size: 18px;
            font-weight: 600;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }

        span {
            font-size: 12px;
            font-weight: 500;
            color: $gray;
        }
    }
}