.fade.modal-backdrop.show {
  background-color: $background;
  opacity: 0.5;
}

.modal-dialog {
  .modal-content {
    border: none;
    background-color: $white;
  }
}

.bus-rides-container {
  margin-top: 100px;

  .phone-body {
    justify-content: center;
  }
}