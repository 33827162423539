.news-info-card-main {
    background-color: $background;
    border-radius: 14px;
    font-family: Arial, sans-serif;
    display: block;
    justify-content: space-between;

    .news-card-image-main {
        border-radius: 10px;
        width: 100%;
        height: 200px;
    }

    .news-card-title-main {
        margin: 10px 0 0 0;
        color: $primary-black;
        font-size: 18px;
        font-weight: 500;
        min-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .news-card-description-main {
        color: #797979;
        font-size: 14px;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .news-read-more {
        color: $primary-orange;
        font-size: 12px;
        text-decoration: none;
        font-weight: 600;
    }

    .news-read-more:hover {
        color: darkorange !important;
        letter-spacing: 0.2px;
        box-shadow: none;
    }

    .news-card-text {
        min-height: 150px;
    }

    .news-tag-text {
        margin: 15px 15px 15px 15px;
        color: $white;
        font-size: 16px;
        text-decoration: none;
        font-weight: 600;
    }

    .news-tag {
        background-color: $primary-orange;
        position: absolute;
        left: 15px;
        /* Poziționare la stânga */
        bottom: 10px;
        /* Poziționare în jos */
        border-radius: 7px;
    }

    .news-image-container {
        position: relative;
    }
}