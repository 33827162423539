.destination-container {
    background-color: $background;
    min-height: 100vh;
    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: $primary-black;
        margin-top: 20px;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .destination-text {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: $primary-orange !important;
    }
    .destination-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (max-width: 500px) {
            width: 90%;
        }
    }

    .calendars-container {
        display: flex;
        justify-content: space-between;

        @media (max-width: 500px) {
            display: block;
        }
        .calendar-body {
            width: 45%;
            @media (max-width: 900px) {
                width: 100%;
            }
        }
    }
}