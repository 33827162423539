.card {
  // max-width: 27.5rem;
  height: 23.5rem;
  border-radius: 14px;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 5px 5px 0px $box_shadow;
  transition: opacity 0.5s ease; 
  opacity: 0; 
}

.card.loaded {
  opacity: 1; 
}

.card-img-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Dark overlay with 30% opacity */
  border-radius: 14px;
}

.card-content {
  border-radius: 8px;
  background-color: $white;
  color: $primary-black; /* Set text color to white for better visibility */
  padding: 15px;
}

.card-title {
  margin-top: 20px;
  z-index: 1;
  margin-left: 25px;
  font-weight: bolder;
  font-size: 20px; /* Increased the font size for the country name */
  color: $white; /* Set text color to white for better visibility */
}

.card-content {
  .card-text {
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    color: $primary-black; /* Set text color to white for better visibility */

    @media (max-width: 850px) {
      &-small {
        display: block;
        font-size: 12px;
        span {
            font-size: 12px;
        }
    }
    }
  }
}

.price-color {
  font-size: 16px;
  font-weight: bold;
  color: $primary-orange; /* Change this color to the one you desire */
}
