.app-promote-container {
    height: auto;
    width: auto;
    margin: auto;
    margin: 50px 0 10px 0;
    padding: 30px 0 30px 0;
    background-size: cover;
    background-position: center;
    background-image: url("../assets/app-promote.jpg");

    .app-promote-body {
        width: 62.5%;
        margin: auto;
        display: flex;
        flex-direction: row;
        gap: 100px;

        @media (max-width: 850px) {
            width: 90%;
            flex-direction: column;
            gap: 25px;
        }
    }

    .app-promo-images {
        display: flex;
        gap: 35px;
        flex-direction: row;

        @media (max-width: 850px) {
            gap: 15px;
            justify-content: center;
        }
    }

    .promo-image {
        max-height: 450px;
        height: auto;
        width: auto;

        @media (max-width: 850px) {
            max-height: 300px;
        }
    }

    .app-promo-right {
        display: flex;
        gap: 15px;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 850px) {
            gap: 8px;
        }
    }

    .text-title {
        color: $primary-orange;
        font-size: 25px;
        font-weight: 600;
        line-height: 27.841px;

        @media (max-width: 850px) {
            font-size: 22px;
        }
    }

    .text-description {
        color: $white;
        font-size: 20px;
        font-weight: 500;
        line-height: 27.841px;

        @media (max-width: 850px) {
            font-size: 16px;
        }
    }

    .app-promo-download {
        display: flex;
        gap: 30px;
        flex-direction: row;

        @media (max-width: 850px) {
            gap: 15px;
            flex-direction: column;
        }
    }

    .promo-image-download {
        height: 60px;
        width: auto;

        @media (max-width: 850px) {
            height: auto;
            align-self: center;
            width: 60%;
        }
    }
}