//EuroFratello - colors
$primary-black : #000000;
$primary-orange: #e55725;
$white: #fff;
$background: #F9F9F9;
$text_grey: #1b3b36;
$text_light: #fffcf6;
$background: #F9F9F9;
$box_shadow: #00000040;
$gray: #797979;
