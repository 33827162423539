.rentals-container {
    background-color: $background;
    min-height: 100vh;

    .rentals-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        @media (max-width: 500px) {
            width: 90%;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }

        .switch-container {
            width: 80%;
            margin: auto;
            @media (max-width: 500px) {
                width: 90%;
            }
    
            @media (max-width: 800px) {
                width: 90%;
            }
        }
    
        .rentals-grid {
            margin-top: 40px;
        }
    }
    
}