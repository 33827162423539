.news-details-container {
    background-color: $background;
    min-height: 100vh;
    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: $primary-black;
    }

    .news-details-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        margin-bottom: 40px;

        @media (max-width: 500px) {
            width: 90%;
        }
    }

    .news-details-grid {
        display: flex;

        @media (max-width: 500px) {
            display: block;
        }

        @media (max-width: 900px) {
            display: block;
        }
    }

    .news-details-others-grid {
        display: block;
        margin-left: 20px;
    }
}