.parcels-container {
    background-color: $background;
    min-height: 100vh;
    
    .countries-card-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;

        @media (max-width: 500px) {
            width: 90%;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            color: $primary-black;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }

        ul {
            padding-top: 10px;
            list-style-type: disc;
            margin-bottom: 50px;
        }

        li {
            padding-top: 10px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }

    .text-orange {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: $primary-orange;
    }

    .countries-grid-container {
        margin-top: 20px;
    }

    .title-container-2 {
        margin-top: 50px;
    }

    .line {
        margin-top: 30px;
        border-top: 2px solid $primary-black;
    }

    .title-container-3 {
        margin-top: 30px;
    }

    .title-obs {
        font-size: 20px;
        font-weight: 600;
        font-style: bold;
        line-height: 20px;
        color: $primary-orange;
    }
}