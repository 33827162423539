.rental-card {
    background-color: $white;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 4.7px 4.7px 0px $box_shadow;
    margin-top: 20px;

    @media (max-width: 1200px) {
        display: block;
        
    }
    

    .rental-image {
        height: 100%;
        width: 270px;
        border-top-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
        object-fit: cover;
        @media (max-width: 1200px) {
            height: 270px;
            width: 100%;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 10px;
            object-fit: cover;
            
        }
    }

    .rental-info-body {
        display: block;
    }

    h2 {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 18px;
        font-weight: 600;
        color: $primary-orange;
    }

    ul {
        list-style-type: disc;
        color: $primary-orange;
    }

    li {    
        font-size: 14x;
        font-weight: 600;
        color: $primary-black;
    }

    span {
        font-size: 14x;
        font-weight: 400;
        color: $primary-black;
    }

    p {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        font-size: 14x;
        font-weight: 400;
        color: $primary-black;
    }
}