.contact-us-form-body {
    background-color: $white;
    display: block;
    border-radius: 10px;
    padding: 20px;

    h2 {
        font-size: 16px;
        font-weight: 500;
        color: $primary-black;
    }

    .btn-link:hover {
        color: $primary-black !important;
        box-shadow: none;
    }

    .btn-link {
        color: $primary-orange !important;
    }

    .form-body {
        display: block;
        margin-left: 40px;
        margin-right: 40px;

        input {
            font-size: 16px;
            margin-top: 10px;
            width: 100%;
            height: 50px;
            padding: 10px;
            border: 1px solid $gray;
            border-radius: 10px;
        }

        .subject-dropdown {
            border: 1px solid $gray;
            border-radius: 10px;
            height: 50px;
            margin-top: 10px;
            padding-top: 10px;
            padding-left: 10px;
        }

        .message-text {
            font-size: 16px;
            margin-top: 10px;
            width: 100%;
            height: 122px;
            padding: 10px;
            border: 1px solid $gray;
            border-radius: 10px;
        }
        .send-button:hover {
            background-color: darkorange !important;
          }
        .send-button {
            background-color: $primary-orange;
            color: $white;
            padding: 12px 60px;
            border: none;
            font-size: 16px;
            border-radius: 13px;
            box-shadow: 0px 4px 4px 0px $box_shadow;
            margin-top: 10px;
        }

        .button-container {
            display: flex;
            justify-content: end;
        }
    }



}