.extra-info-card {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    h2 {
        font-size: 16px;
        font-weight: 600;
        color: $primary-black;
    }

    .extra-info-body {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
        .extra-info-body {
            display:  block;
        }

        .message-text {
            font-size: 16px;
            margin-top: 10px;
            width: 100%;
            height: 122px;
            padding: 10px;
            border: 1px solid $gray;
            border-radius: 10px;
        }
    }
}