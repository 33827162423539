.ticket-container {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    display: block;
    margin-bottom: 30px;

    h3 {
        font-size: 16px;
        font-weight: 600;
        color: $primary-black;
    }

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: $primary-black;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        color: $primary-black;

        @media (max-width: 900px) {
            font-size: 12px;
        }
    }

    .title-container {
        display: flex;
        justify-content: space-between;

        .orange-title {
            font-size: 16px;
            font-weight: 600;
            color: $primary-orange;
        }
    }

    .route-container {
        display: flex;
        margin-top: 10px;
        height: 100px;

        .ticket-icon {
            object-fit: cover;
            margin-top: 7px;
            margin-bottom: 7px;
        }

        .destinations-container {
            height: 100%;
            background-color: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 10px;
        }
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
    }
}