.dropdown {
  position: relative;

  .selected-option {
    color: $primary-orange;
    background-color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.841px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected-option-hint {
    color: gray;
    font-size: 15px;
    font-style: bold;
    font-weight: 500;
    line-height: 27.841px;
  }

  .options {
    max-height: 350px;
    overflow-y: auto;
    z-index: 5;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    align-items: center;
    text-align: center;
    display: none;
    background-color: white;
    opacity: 0;
    padding: 10px;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
    box-shadow: 0px 4px 4px 0px $box_shadow;
    border-radius: 13px;
  }

  .options.show {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .option {
    margin-bottom: 10px;
    color: black;
    font-size: 15px;
    font-style: bold;
    font-weight: 700;
    line-height: 27.841px;
  }

  .option.selected,
  .option:hover {
    background-color: $primary-orange;
    color: white
  }

  .search-input {
    max-width: 90%;
  }

  .search-input:focus {
    outline-width: 0px;
    border: 1px solid $primary-orange;
  }
}