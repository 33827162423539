.welcome-top-container {
  background-color: $background;
}

.welcome-top {
  &-container {
    margin-bottom: 9rem;
    position: relative;

    @media (max-width: 1100px) {
      margin-bottom: 0rem;
    }
  }

  &-section {
    background-image: url("../assets/shuttle-bus.svg");
    background-size: cover; // Change to contain to fit the image without cropping
    background-position: center; // This will center the image in the container
    width: 100%;
    height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    position: relative;

    &::before {
      // Pseudo-element for the overlay
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
      z-index: 1; // Ensures the overlay is above the background image but below the content
    }

    >* {
      // Styles for direct children of .welcome-top-section
      z-index: 2; // Ensures the content is above the overlay
      position: relative; // This is required for z-index to take effect
    }
  }

  @media (max-width: 767px) {
    &-section {
      background-image: url("../assets/mobile_welcome.jpeg");
      background-size: cover; 
      background-position: center;
      height: 27rem;
    }
  }
}

.welcome-top-section-text {
  color: $white;
  width: 62.5%;
  margin: auto;
  
  h2 {
    line-height: 47px;
    font-weight: bold;
    font-size: 40px;

    @media (max-width: 500px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 24px;

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  @media (max-width: 500px) {
    width: 85%;
  }
}

.contact-buttons {
  display: none;
}

@media only screen and (max-width: 600px) {
  .contact-buttons {
    width: 90%;
    display: flex;
    position: absolute;
    flex-direction: row;
    margin-bottom: 25px;
    bottom: 0;
    gap: 10px;

    .send-button {
      width: 100%;
      height: 45px;
      background-color: #E55725e9;
      color: $white;
      border: none;
      font-size: 16px;
      border-radius: 13px;
      box-shadow: 0px 4px 4px 0px $box_shadow;
      margin-top: 20px;
    }

    .call-button {
      background-color: #0aa37de9;
    }
  }
}

.travel-item-search {
  display: flex;
  justify-content: center;
}

.destination-card-body {
  width: 62.5%;
  margin: auto;
  padding-top: 75px;

  @media (max-width: 1200px) {
    width: 90%;
  }
}

.destination-options-container {
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: $primary-black;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $primary-orange;
  }
}

.destinations-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Create 3 equal columns */
  gap: 1.5rem;

  /* Provide spacing between cards */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    /* Display 2 items per row on medium screens */
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    /* Display 1 item per row on small screens */
  }
}

.travel-with-us-container {
  width: 62.5%;
  margin: auto;
  padding: 50px 0 0 0;

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: $primary-black;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $primary-black;
  }
}

.travel-with-us-grid {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 1.5rem 0;
  flex-wrap: nowrap;
  gap: 20px;
  flex-direction: row;

  @media (max-width: 850px) {
    display: block;
  }
}

.divider {
  align-self: center;
  background-color: black;
  width: 1.5px;
  margin-top: 20px;
  height: 45px;

  @media (max-width: 850px) {
    display: none;
  }
}

.news-container {
  margin: 30px 0 50px 0;
  padding: 50px 0 50px 0;
  background-color: rgb(229 88 37 / 10%);
}

.news-container-body {
  width: 62.5%;
  margin: auto;

  @media (max-width: 500px) {
    width: 90%;
  }
}

.news-top-section {
  display: flex;
  justify-content: space-between;
}

.news-left {
  flex: 1;

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: $primary-black;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $primary-orange;
  }
}

.news-see-all {
  color: $primary-orange;
  font-size: 16px;
  font-weight: 600;
}

.news-see-all:hover {
  color: darkorange !important;
  letter-spacing: 0.5px;
  box-shadow: none;
}

.news-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    /* Display 2 items per row on medium screens */
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    /* Display 1 item per row on small screens */
  }
}

.social-media-section {
  width: 62.5%;
  margin: auto;
  display: flex;
  padding-top: 20px;

  h2 {
    font-size: 15px;
    align-self: center;
    font-weight: 600;
    margin: 0 5px 0 0;
    color: #3b4b53;
  }
}