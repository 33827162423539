.buy-ticket-container {
    background-color: $background;
    min-height: 100vh;

    .progress-bar-container {
        width: 80%;
        margin: auto;
        padding-top: 20px;

        @media (max-width: 500px) {
            width: 90%;
        }
    }

    .info-message {
        margin-top:  20px;
    }

    .bold-text {
        color: $primary-black;
        font-size: 16px;
        font-weight: 600;
    }

    .orange-text {
        color: $primary-orange;
        font-size: 16px;
        font-weight: 600;
    }

    .buy-ticket-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 900px) {
            display: block;
        }

        @media (max-width: 500px) {
            width: 90%;
        }

        .inputs-body {
            display: block;
            width: 50%;

            @media (max-width: 900px) {
                width: 100%
            }

        }

        .info-body {
            display: block;
            width: 45%;
            margin-top: 0px;

            @media (max-width: 900px) {
                margin-top: 20px;
                width: 100%
            }
        }

        .button-container {
            display: flex;
            justify-content: end;
        }

        .send-button:hover {
            background-color: darkorange !important;
        }

        .send-button {
            background-color: $primary-orange;
            color: $white;
            padding: 12px 60px;
            border: none;
            font-size: 16px;
            border-radius: 13px;
            box-shadow: 0px 4px 4px 0px $box_shadow;
            margin-top: 20px;
        }

        .price-container {
            background-color: $white;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
        
            .price-text {
                font-size: 16px;
                font-weight: 600;
                color: $primary-orange;
            }
        }
    }
}