.person-input-card {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;

    span {
        font-size: 14px;
        font-weight: 600;
    }

    
    input {
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        height: 50px;
        padding: 10px;
        border: 1px solid $gray;
        border-radius: 10px;
    }
}