.invoice-card {
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    h2 {
        font-size: 16px;
        font-weight: 600;
        color: $primary-black;
    }

    .invoice-inputs-body {
        display: block;
        margin-left: 30px;
        margin-right: 30px;
    }

    input {
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        height: 50px;
        padding: 10px;
        border: 1px solid $gray;
        border-radius: 10px;
    }

    .btn-link:hover {
        color: $primary-black !important;
        box-shadow: none;
    }

    .btn-link {
        color: $primary-orange !important;
    }
}