.header-container {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .image-container {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;

        .header-image {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 100;
            pointer-events: none;
        }
    }

    .header-body {
        position: absolute;
        display: block;
        flex-direction: column;
        justify-content: baseline;
        width: 100%;
        height: 100%;
    }

    .header-details {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;

        .details-body {
            display: block;

            .phone-container {
                background-color: $primary-orange;
                border-radius: 10px;
                padding: 20px;
                margin-top: 20px;
            }
        }

        .details-text {
            font-size: 16px;
            font-weight: 500;
            color: $white;
        }

    }

    .header-tag {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title-text {
            font-size: 35px;
            font-weight: 700;
            color: $white;
        }

        .description-container {
            display: flex;
            align-items: center;
            margin-top: 30px;
        }

        .description-text {
            font-size: 18px;
            font-weight: 700;
            color: $white;
        }

        .separator-icon {
            width: 7px;
            height: 17px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .details-container {
            display: flex;
            flex-direction: column;
            position: absolute;

            .details-title {
                font-size: 16px;
                font-weight: 500;
                color: $white;
            }

            .phone-container {
                background-color: $primary-orange;
                padding: 20px;
                border-radius: 10px;

                .phone-text {
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                }
            }
        }
    }
}

.header-container-colete {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .image-container {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;

        .header-image {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 100;
            pointer-events: none;
        }
    }

    .header-body {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
        //height: 100%;
    }

    .header-details {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 40px;
        margin-top: 100px;

        @media (max-width: 900px) {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 50px;
        }

        .details-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            .phone-container {
                background-color: $primary-orange;
                border-radius: 10px;
                padding-left: 20px;
                padding-top: 20px;
                padding-right: 20px;
                padding-bottom: 10px;
                margin-top: 20px;
            }
        }

        .details-text {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: $white;
        }

    }

    .header-tag {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title-text {
            font-size: 35px;
            font-weight: 700;
            text-align: center;
            color: $white;
        }

        .description-container {
            display: flex;
            align-items: center;
            margin-top: 30px;
        }

        .description-text {
            font-size: 18px;
            font-weight: 700;
            color: $white;
        }

        .separator-icon {
            width: 7px;
            height: 17px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .details-container {
            display: flex;
            flex-direction: column;
            position: absolute;

            .details-title {
                font-size: 16px;
                font-weight: 500;
                color: $white;
            }

            .phone-container {
                background-color: $primary-orange;
                padding: 20px;
                border-radius: 10px;

                .phone-text {
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                }
            }
        }
    }
}