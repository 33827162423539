.navbar .navbar-nav .nav-link {
  margin-right: 35px;
  padding: 20px 0;
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: $primary-orange !important;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }

  .navbar .dropdown-menu a.active:hover {
    color: white !important;
  }

  .navbar .dropdown-menu a:hover {
    color: $primary-orange !important;
  }
}

.dropdown-menu .dropdown-item.active {
  background-color: $primary-orange;
}

.navbar .dropdown-menu a:active {
  background-color: rgba(255, 140, 0, 0);
  border: none;
}

.navbar .btn-primary {
  background-color: $primary-orange;
  border: none;
}

.navbar .btn-primary:hover {
  background-color: darkorange;
}

.navbar .btn-primary:active {
  background-color: darkorange;
}
