.info-page-container {
    background-color: $background;
    min-height: 100vh;

    .html-content {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}