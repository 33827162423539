.news-details-card {
    display: block;

    .news-details-card-image {
        border-radius: 10px;
        width: 100%;
        height: 40%;
        object-fit: cover;
    }

    h2 {
        font-size: 18px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .news-details-card-container {
        display: flex;
        margin-top: 10px;

        @media (max-width: 500px) {
            display: block;
        }

        @media (max-width: 900px) {
            display: block;
        }
    }

    .news-details-tag {
        background-color: $white;
        margin-right: 10px;
        padding: 10px;
        text-align: center;
        border-radius: 10px;
        display: flex;

        @media (max-width: 500px) {
            margin-top: 10px;
            margin-right: 0px;
        }

        @media (max-width: 900px) {
            margin-top: 10px;
            margin-right: 0px;
        }

        .news-details-tag-image {
            width: 17px;
            height: 17px;
            margin-right: 10px;
        }

        span {
            font-size: 14px;
            font-weight: 500;
            color: $gray
        }
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: $gray
    }

}