.switch-tab-body {
    background-color: $white;
    border:  1px solid $primary-orange;
    border-radius: 10px;
    padding: 5px;
    .tab-bar {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 0px;
        /* Aliniază tab-urile în centru pe orizontală */
        li {
            cursor: pointer;
            padding: 10px;
            border-radius: 10px;
            background-color: $white;
            width: 50%;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            /* Fundal alb pentru tab-ul nefocusat */
            color: $primary-orange;
            /* Text portocaliu pentru tab-ul nefocusat */
            transition: background-color 0.3s, color 0.3s;
            /* Animare la schimbarea culorilor */
        }

        li.active {
            background-color: $primary-orange;
            /* Fundal portocaliu pentru tab-ul selectat */
            color: $white;
            /* Text alb pentru tab-ul selectat */
        }
    }
}