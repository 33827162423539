.buy-ticket-finish-container {
    background-color: $background;
    min-height: 100vh;

    .progress-bar-finish-container {
        width: 80%;
        margin: auto;
        padding-top: 20px;

        @media (max-width: 500px) {
            width: 90%;
        }
    }

    .buy-ticket-finish-body {
        width: 62.5%;
        margin: auto;
        padding-top: 100px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 30px;
            font-weight: 600;
        }
        p {
            font-size: 20px;
            font-weight: 500;
            text-align: justify;
        }

        .send-button:hover {
            background-color: darkorange !important;
        }

        .send-button {
            background-color: $primary-orange;
            color: $white;
            padding: 12px 60px;
            border: none;
            font-size: 16px;
            border-radius: 13px;
            box-shadow: 0px 4px 4px 0px $box_shadow;
            margin-top: 20px;
        }
    }
}