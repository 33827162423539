.price-item-container {
    background-color: $white;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    margin-top: 10px;

    .text-container {
        display: flex;
        width: 25%;
        padding-right: 5px;
        
    }

    span {
        font-size: 18px;
        font-weight: 500;
        color: $primary-orange !important;
        @media (max-width: 500px) {
            font-size: 16px;
        }
    }
}