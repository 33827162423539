.locations-container {
    background-color: $background;
    min-height: 100vh;
    
    .locations-body {
        width: 62.5%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (max-width: 500px) {
            width: 90%;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            color: $primary-black;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }

        .orange-text {
            margin-top: 40px;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            color: $primary-orange;
        }
    }
}