
.calendar {
    width: 100%;
    font-family: Arial, sans-serif;
    background-color: $white;
    border-radius: 10px;
  
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      border-radius: 10px;
  
      .month-name {
        margin-left: 20px;
        padding: 10px;
        font-size: 20px;
        font-weight: 600;
      }
  
      .arrows {
        display: flex;
        align-items: center;
        margin-right: 20px;
  
        .prev-arrow, .next-arrow {
          cursor: pointer;
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  
    &-days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      padding: 10px;
    }
  
    &-day-label {
      background-color: $white;
      padding: 10px;
      text-align: center;
      color: $primary-black;
      font-weight: 600;
    }
  
    &-day {
      background-color: $white;
      padding: 10px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #f0f0f0;
  
      &.empty {
        background-color: #f0f0f0;
      }
  
      &.selected {
        background-color: $primary-orange; // Background portocaliu pentru zilele selectate
        color: #fff; // Text alb pentru zilele selectate
      }
  
      &.excluded {
        background-color: #fff; // Background alb pentru zilele excluse
      }
    }
  }