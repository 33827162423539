.progress-bar-container {
    display: flex;
    align-items: center;
    width: 100%;

    .progress-icon {
        width: 50px;
        height: 50px;
        object-fit: cover;

        @media (max-width: 500px) {
            width: 30px;
            height: 30px;
        }

    }

    .progress-icon-selected {
        width: 55px;
        height: 55px;
        object-fit: cover;

        @media (max-width: 500px) {
            width: 35px;
            height: 35px;
        }

    }

    .orange-line {
        height: 3px;
        width: 40%;
        background-color: $primary-orange;
    }

    .dark-line {
        height: 3px;
        background-color: $primary-black;
        width: 40%;
    }
}