.locations-card-body {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    @media (max-width: 500px) {
        flex-direction: column;
        align-items:start;
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items:start;
    }


    h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: $primary-black;
    }

    span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: $primary-black;
    }

    .phones-grid {
        display: flex;
        @media (max-width: 1200px) {
            margin-top: 10px;
            flex-direction: column;
        }
    }
}