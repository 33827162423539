.c-footer {
  background-color: $primary-black;
  padding: 40px 0;

  &-container {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .c-midle-left-section {
    color: $white;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .btn-link:hover {
    color: var(--light);
    letter-spacing: 1px;
    box-shadow: none;
  }

  hr {
    height: 1px;
    background: #797979;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }

  .quick-links {
    list-style: none;
    padding: 0;
  }

  .quick-links li {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .quick-links li i {
    margin-right: 10px;
    color: $primary-orange;
  }

  .c-midle-right-section {
    color: $white;
  }

  .contact-links {
    list-style: none;
    padding: 0;
  }

  .contact-links li {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .contact-links li i {
    margin-right: 10px;
    color: $primary-orange; // Making the icons orange
  }

  .phone-body {
    justify-content: start;

    .phone-number {
      color: $white;
    }
  }

  .c-right-section {
    color: $white;

    a {
      color: $primary-orange;
    }
  }

  .newsletter-input-group {
    display: flex;
    margin: 20px 0;
  }

  .newsletter-input-group input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 12px 0 0 12px;
  }

  .newsletter-input-group button {
    padding: 10px 15px;
    border: none;
    background-color: $primary-orange;
    color: $white;
    border-radius: 0 12px 12px 0;
    cursor: pointer;
  }

  .newsletter-input-group button:hover {
    background-color: darkorange;
  }

  small {
    display: block;
    margin-top: 10px;
    font-size: 12px;
  }

  .logo {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .c-left-section p {
    font-size: 16px;
    color: $white;
  }

  .c-left-section,
  .c-midle-left-section,
  .c-midle-right-section,
  .c-right-section {
    padding: 30px 20px;
  }

  .bottom-section {
    padding: 10px 0;
    background-color: $primary-black;
  }

  .bottom-section-content {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }

  .bottom-section-content p {
    margin: 0;
    display: inline-block;
  }

  .logo {
    width: 80%;
  }
}