.c-divider {
  display: block;
  height: 1px;
  width: 100%;
  background-color: $white;

  &.c-horizontal-divider {
    height: 37px;
    width: 1px;
    background-color: $white;
  }
}
